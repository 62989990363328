import React from 'react';
import { motion } from 'framer-motion';

const HexagonCard = ({ image, title, onClick }) => {
    return (
        <motion.div
            className="w-64 h-72 cursor-pointer"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onClick}
        >
            <div className="relative w-full h-full">
                <div 
                    className="absolute inset-0 bg-cover bg-center"
                    style={{
                        backgroundImage: `url(${image})`,
                        clipPath: 'polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)'
                    }}
                />
                <div className="absolute inset-x-0 top-0 pt-4 flex justify-center">
                    <div className="bg-black bg-opacity-50 p-2 rounded">
                        <h3 className="text-white text-center text-lg font-semibold">{title}</h3>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default HexagonCard;