export interface JobPosition {
  id: number;
  title: string;
  description: string;
  responsibilities: string[];
  skillsrequired: string[];
  desirables: string[];
  employeebenefits: string[];
  personspecification: string[];
}

export const jobPositions: JobPosition[] = [
  {
    id: 1,
    title: "Software Engineer",
    description: "We are looking for a skilled software engineer to join our team.",
    responsibilities: ["Develop and maintain software applications", "Collaborate with cross-functional teams"],
    skillsrequired: ["Proficiency in programming languages", "Knowledge of software development methodologies"],
    desirables: ["Experience with cloud computing platforms", "Familiarity with agile development"],
    employeebenefits: ["Competitive salary", "Health insurance", "Paid time off"],
    personspecification: ["Strong problem-solving skills", "Excellent communication skills"]
  },
  {
    id: 2,
    title: "Research Scientist",
    description: "We are seeking a talented research scientist to drive innovation in our team.",
    responsibilities: ["Conduct cutting-edge research", "Publish findings in peer-reviewed journals"],
    skillsrequired: ["PhD in relevant field", "Strong analytical and problem-solving skills"],
    desirables: ["Experience with machine learning", "Publication record in top-tier journals"],
    employeebenefits: ["Competitive salary", "Research budget", "Conference attendance support"],
    personspecification: ["Innovative thinker", "Excellent written and verbal communication skills"]
  },
  {
    id: 3,
    title: "Product Manager",
    description: "We're looking for a product manager to lead our product development efforts.",
    responsibilities: ["Define product strategy", "Collaborate with engineering and design teams"],
    skillsrequired: ["Experience in product management", "Strong analytical skills"],
    desirables: ["MBA or relevant advanced degree", "Experience in the tech industry"],
    employeebenefits: ["Competitive salary", "Stock options", "Professional development budget"],
    personspecification: ["Strategic thinker", "Excellent leadership and communication skills"]
  },
  
];