import React, { useState } from 'react';
import { motion, useInView } from 'framer-motion';
import { View, Heading } from "@aws-amplify/ui-react";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { CheckCircle, Activity, RefreshCw } from 'lucide-react';
import AlgorithmPopup from '../../components/AlgorithmPopup';
import HexagonCard from '../../components/HexagonCard';
import algorithms from '../../data/algorithmdata.json';
import AnimatedGraph from '../../components/AnimatedGraph';

export default function FrontPage() {
    return (
        <div className="flex flex-col items-center w-full">
            <Header />
            <ColorBox />
            <CompareSliderSection />
            {/* <AnimatedGraph /> */}
            <AlgoSection algorithms={algorithms} />
            <Details />
            <Partners />
            <BottomBanner />
        </div>
    );
}

function Header() {
    return (
        <div className="w-screen bg-cover bg-center flex flex-col justify-center items-center"
            style={{ backgroundImage: "url(./HeroHeader2.svg)" }}>
            <h1
                className="text-white text-center text-[clamp(1.2rem,2vw,2.5rem)] 
                         font-bold leading-tight p-[calc(9vw/2)] 
                         drop-shadow-[0_2px_2px_rgba(0,0,0,0.8)]"
                id="main-heading"  // Add this
                role="heading"     // Add this
                aria-level="1"     // Add this
            >
                ARTIFICIAL INTELLIGENCE SOLUTIONS FOR DIGITAL PATHOLOGY
            </h1>
        </div>
    );
}

function CompareSliderSection() {
    const legendItems = [
        { color: '#FF0000', label: 'CD8+ Immune Cells' },
        { color: 'Orange', label: 'CD4+ Immune Cells' },
        { color: '#00FF00', label: 'Macrophages' },
        { color: 'Purple', label: 'B Cells' },
        { color: 'Blue', label: 'Goblets cells, epithelial and other cells' },
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.3,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, ease: "easeOut" },
        },
    };
    return (
        <div className="py-8 px-4 sm:px-5 flex flex-col items-center w-full bg-gray-50">
            <h2 className="text-[clamp(1.2rem,2vw,1.5rem)] font-bold mb-4 text-center">
                Detect and Label Every Cell
            </h2>
            <div className="w-20 h-0.5 bg-black mb-8 sm:mb-12"></div>

            <motion.div
                className="flex flex-col lg:flex-row justify-center items-center lg:items-start gap-8 lg:space-x-8 w-full max-w-8xl"
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }}
            >
                <motion.div className="w-full lg:w-1/3 px-4 sm:pr-8" variants={itemVariants}>
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4">Advanced Cell Detection</h3>
                    <p className="text-sm sm:text-base text-gray-700 mb-4">
                        Our AI-powered solution revolutionizes cell detection and classification in digital pathology.
                        By leveraging cutting-edge machine learning algorithms, we can accurately identify and label
                        various cell types within complex tissue samples.
                    </p>
                    <p className="text-sm sm:text-base text-gray-700 mb-6">
                        This technology enables pathologists to gain deeper insights into tissue composition,
                        tumor microenvironments, and immune cell infiltration, ultimately leading to more
                        precise diagnoses and personalized treatment strategies.
                    </p>
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4">Improved Accuracy</h3>
                    <p className="text-sm sm:text-base text-gray-700 mb-6">
                        Our advanced algorithms significantly improve the accuracy of cell detection and classification,
                        reducing the likelihood of misdiagnosis and enhancing the overall quality of pathological assessments.
                    </p>
                    <h3 className="text-xl sm:text-2xl font-semibold mb-4">Time-Saving Analysis</h3>
                    <p className="text-sm sm:text-base text-gray-700">
                        By automating the cell detection and labeling process, our technology saves valuable time for
                        pathologists, allowing them to focus on interpreting results and making critical decisions for
                        patient care.
                    </p>
                </motion.div>

                <motion.div
                    className="w-full max-w-[600px] rounded-lg shadow-md overflow-hidden"
                    variants={itemVariants}
                >
                    <ReactCompareSlider
                        itemOne={<ReactCompareSliderImage src="/overlay_alpha0.png" alt="Before" />}
                        itemTwo={<ReactCompareSliderImage src="/overlay_alpha09.png" alt="After" />}
                    />
                </motion.div>

                <motion.div
                    className="w-full lg:w-80 bg-white rounded-lg p-4 shadow-md"
                    variants={itemVariants}
                >
                    <h3 className="text-lg font-semibold mb-4">Cell Types</h3>
                    {legendItems.map((item, index) => (
                        <div key={index} className="flex items-center mb-2">
                            <div className="w-4 h-4 rounded-full mr-2" style={{ backgroundColor: item.color }}></div>
                            <span className="text-sm sm:text-base">{item.label}</span>
                        </div>
                    ))}
                </motion.div>
            </motion.div>
        </div>
    );
}

function Details() {
    const containerRef = React.useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.3 });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.3,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, ease: "easeOut" },
        },
    };

    return (
        <div className="py-8 px-5 flex flex-col items-center w-full bg-gray-50" ref={containerRef}>
            <h2 className="text-3xl font-bold text-center mb-12">Continuously Improving Pathology AI</h2>
            <div className="w-20 h-0.5 bg-black mb-12"></div>

            <motion.div
                className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8"
                variants={containerVariants}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
            >
                <motion.div
                    className="flex flex-col items-center text-center p-4"
                    variants={itemVariants}
                >
                    <CheckCircle className="w-12 h-12 mb-4 text-blue-600" />
                    <h3 className="text-xl font-semibold mb-2">Accurate Diagnosis</h3>
                    <p className="text-gray-600">Our AI models are trained on extensive, expert-reviewed datasets, ensuring high accuracy in identifying various pathological features and patterns.</p>
                </motion.div>
                <motion.div
                    className="flex flex-col items-center text-center p-4"
                    variants={itemVariants}
                >
                    <Activity className="w-12 h-12 mb-4 text-blue-600" />
                    <h3 className="text-xl font-semibold mb-2">Rapid Analysis</h3>
                    <p className="text-gray-600">Our AI-powered tools significantly reduce analysis time, allowing pathologists to process and interpret slides more efficiently without compromising accuracy.</p>
                </motion.div>
                <motion.div
                    className="flex flex-col items-center text-center p-4"
                    variants={itemVariants}
                >
                    <RefreshCw className="w-12 h-12 mb-4 text-blue-600" />
                    <h3 className="text-xl font-semibold mb-2">Continuous Improvement</h3>
                    <p className="text-gray-600">Our AI models undergo regular updates and refinements based on new data and feedback from pathologists, ensuring they stay at the cutting edge of digital pathology.</p>
                </motion.div>
            </motion.div>
            <p className="text-center text-sm text-gray-500 mt-8">
                Our AI models are subject to regulatory clearance and may not be available in all markets.
            </p>
        </div>
    );
}

function ColorBox() {
    return (
        <div className="p-3 flex flex-col items-center justify-center w-full bg-[#01acc2] mb-5">
            <p className="text-white text-center text-[clamp(0.9rem,1.2vw,1.2rem)] max-w-[900px] p-0.5 mb-2.5">
                At Octopath, we aim to provide AI-powered solutions to assist pathologists in performing their daily tasks. Our
                goal is to accelerate diagnosis workflows so that clinicians can focus on improving patient outcomes and care.
            </p>
            <button
                className="text-[#01acc2] bg-white rounded-full text-[clamp(0.8rem,1vw,1rem)] mb-2.5 px-6 py-2 font-semibold cursor-pointer transition-all duration-300 hover:bg-opacity-90 hover:scale-105 shadow-md"
                onClick={() => window.location.href = '/dashboard'}
            >
                Try it yourself!
            </button>
        </div>
    );
}

function AlgoSection({ algorithms }) {
    const [selectedAlgorithm, setSelectedAlgorithm] = useState(null);
    const containerRef = React.useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.3 });

    const openPopup = (algorithm) => {
        setSelectedAlgorithm(algorithm);
    };

    const closePopup = () => {
        setSelectedAlgorithm(null);
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.3,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, ease: "easeOut" },
        },
    };

    return (
        <div className="py-8 px-5 flex flex-col items-center w-full bg-gray-50" ref={containerRef}>
            <h2 className="text-[clamp(0.5rem,2vw,1.5rem)] font-bold mb-4">AI Models</h2>
            <div className="w-24 h-0.5 bg-black mb-6"></div>
            <p className="text-center max-w-2xl mb-12 text-gray-700">
                Our cutting-edge AI models are designed to revolutionize digital pathology.
                Each algorithm specializes in a specific aspect of image analysis,
                from tumor detection to cell counting, enhancing the accuracy and
                efficiency of pathological diagnoses.
            </p>
            <motion.div
                className="flex flex-wrap justify-center gap-8 w-4/5 max-w-[1200px] mx-auto"
                variants={containerVariants}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
            >
                {algorithms.map((algo) => (
                    <motion.div key={algo.id} variants={itemVariants}>
                        <HexagonCard
                            image={algo.images[0]}
                            title={algo.title}
                            onClick={() => openPopup(algo)}
                        />
                    </motion.div>
                ))}
            </motion.div>
            {selectedAlgorithm && (
                <AlgorithmPopup algorithm={selectedAlgorithm} onClose={closePopup} />
            )}
        </div>
    );
}

function Partners() {
    const containerRef = React.useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.3 });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
                delayChildren: 0.3,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.8, ease: "easeOut" },
        },
    };

    return (
        <div className="py-10 px-5 flex flex-col items-center w-full" ref={containerRef}>
            <h2 className="text-[clamp(0.5rem,2vw,1.5rem)] font-bold mb-4">Partners</h2>
            <div className="w-24 h-0.5 bg-black mb-12"></div>
            <motion.div
                className="flex flex-wrap justify-center gap-5 w-full max-w-[1200px] mt-2.5"
                variants={containerVariants}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
            >
                <motion.img variants={itemVariants} src="University_College_London_Hospitals_NHS_Foundation_Trust_logo.svg" alt="UKRI Logo" className="w-[183px] h-[70px] object-contain object-center p-2.5" />
                <motion.img variants={itemVariants} src="RNOH_logo.jpg" alt="Future Leaders Fellowship Logo" className="w-[183px] h-[70px] object-contain object-center p-2.5" />
                <motion.img variants={itemVariants} src="2560px-Amazon_Web_Services_Logo.svg.png" alt="Sarcoma UK Logo" className="w-[183px] h-[70px] object-contain object-center p-2.5" />
                <motion.img variants={itemVariants} src="UCL.svg" alt="UCL Logo" className="w-[183px] h-[70px] object-contain object-center p-2.5" />
                <motion.img variants={itemVariants} src="UKRI_Ai4Health.png" alt="Future Leaders Fellowship Logo" className="w-[183px] h-[70px] object-contain object-center p-2.5" />
                <motion.img variants={itemVariants} src="Sarcomalogo.png" alt="Sarcoma UK Logo" className="w-[183px] h-[70px] object-contain object-center p-2.5" />
            </motion.div>
        </div>
    );
}

function AnimatedGraphSection() {
    const containerRef = React.useRef(null);
    const isInView = useInView(containerRef, { once: true, amount: 0.3 });

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
            },
        },
    };

    return (
        <motion.div
            className="py-16 px-5 w-full bg-gray-50"
            ref={containerRef}
            variants={containerVariants}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
        >
            <h2 className="text-3xl font-bold text-center mb-8">AI Performance Metrics</h2>
            <div className="max-w-4xl mx-auto">
                <AnimatedGraph />
            </div>
        </motion.div>
    );
}

function BottomBanner() {
    return (
        <div className="relative w-full h-[45vh] overflow-hidden">
            <img className="w-full h-full object-cover object-top" src="UCL.png" alt="" />
            <div className="absolute inset-0 bg-gradient-to-b from-[rgba(1,172,194,0.3)] to-[rgba(255,255,255,0.5)] z-10"></div>
        </div>
    );
}