import { Heading } from "@aws-amplify/ui-react";
import SubjectsTable from "../../feature/SubjectsTable";
import WhiteCard from "../../components/WhiteCard";

export default function Subjects() {

    return (
        <>

            <Heading level={4}><strong>Subjects</strong></Heading>

            <WhiteCard>
                <SubjectsTable />
            </WhiteCard>
        </>

    );

}
