import { useEffect, useRef, useState, useMemo } from "react";
import { paintAttentionMap, paintObject, paintBbox } from "./HeatmapHelper";
import FeedBackFormMitosis from '../components/FeedBackFormMitosis';
import { SliderField } from "@aws-amplify/ui-react";
import { fetchObjectData, fetchMitosesBBox } from '../api/api';
import { Square, Circle } from 'lucide-react';

const OpenSeadragon = window.OpenSeadragon;

const CLASS_COLORS = {
  '0': '#81CAD6', // B Cell
  '1': '#BFFF00', // CD4
  '2': '#DC3E26'  // CD8
};

const ViewerComp = ({ url, visibleMap, slideParameters }) => {
  // State and Refs
  const viewerRef = useRef(null);
  const heatmapRef = useRef(null);
  const mppRef = useRef(null);
  const [viewer, setViewer] = useState(null);
  const [threshold, setThreshold] = useState(0.1);
  const [objectData, setObjectData] = useState([]);
  const [bbox, setBbox] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isHeatmapVisible, setIsHeatmapVisible] = useState(true);

  // keyboard event listener
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === 'Space' && event.target === document.body) {
        event.preventDefault(); // Prevent page scroll

        if (isHeatmapVisible) {
          clearHeatmap();
        } else {
          // Re-paint the current map
          if (visibleMap === 'attentionMap') {
            paintAttentionMap(viewer, heatmapRef, slideParameters, mppRef);
          } else if (['mitosesMap', 'imnMap'].includes(visibleMap) && isDataFetched) {
            paintObject(viewer, heatmapRef, filteredObjectData);
            if (bbox && visibleMap === 'mitosesMap') {
              paintBbox(heatmapRef, bbox);
            }
          }
        }
        setIsHeatmapVisible(!isHeatmapVisible);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isHeatmapVisible]);



  // Filtered data based on threshold
  const filteredObjectData = useMemo(() => {
    return objectData.filter(item => Number(item.conf) > threshold);
  }, [objectData, threshold]);

  // Data fetching
  useEffect(() => {
    const fetchMapData = async () => {
      if (!['mitosesMap', 'imnMap'].includes(visibleMap)) {
        setIsDataFetched(true);
        return;
      }

      try {
        const filename = visibleMap === 'mitosesMap' ? 'mitoses_results.csv' : 'IMN_results.csv';
        const params = {
          userId: slideParameters.userId,
          subjectId: slideParameters.subjectId,
          slideId: slideParameters.slideId,
          level: 'protected',
          slidePath: `subjects/${slideParameters.subjectId}/slides/${slideParameters.slideId}`
        };

        const [data, bboxData] = await Promise.all([
          fetchObjectData(params, filename),
          visibleMap === 'mitosesMap' ? fetchMitosesBBox(params) : null
        ]);

        setObjectData(data);
        setBbox(bboxData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsDataFetched(true);
      }
    };

    setIsDataFetched(false);
    fetchMapData();
  }, [visibleMap, slideParameters]);

  // Heatmap management
  const clearHeatmap = () => {
    if (heatmapRef.current) {
      try {
        heatmapRef.current.heatmap._renderer._clear();
        heatmapRef.current.setData({ max: 0, data: [] });
        heatmapRef.current.setBbox(null);
      } catch (error) {
        console.error('Error clearing heatmap:', error);
      }
    }
  };

  // Handle map visibility
  useEffect(() => {
    if (!viewer) return;

    if (visibleMap === null) {
      clearHeatmap();
      return;
    }

    if (visibleMap === 'attentionMap') {
      const paintMap = async () => {
        clearHeatmap();
        await paintAttentionMap(viewer, heatmapRef, slideParameters, mppRef);
      };
      paintMap();
    }

    if (['mitosesMap', 'imnMap'].includes(visibleMap) && isDataFetched) {
      const paintMap = async () => {
        clearHeatmap();
        if (filteredObjectData.length > 0) {
          await paintObject(viewer, heatmapRef, filteredObjectData);
          if (bbox && visibleMap === 'mitosesMap') {
            await paintBbox(heatmapRef, bbox);
          }
        }
      };
      paintMap();
    }
  }, [visibleMap, viewer, isDataFetched, filteredObjectData, bbox, slideParameters]);

  // Viewer initialization
  useEffect(() => {
    if (!url || !OpenSeadragon) return;

    const initializeViewer = async () => {
      if (viewer) {
        viewer.destroy();
        setViewer(null);
      }

      const newViewer = OpenSeadragon({
        element: viewerRef.current,
        prefixUrl: "https://openseadragon.github.io/openseadragon/images/",
        minZoomImageRatio: 0.01,
        visibilityRatio: 0,
        showNavigator: true,
        crossOriginPolicy: "Anonymous",
        ajaxWithCredentials: true,
        sequenceMode: true,
      });

      const tiffTileSources = await OpenSeadragon.GeoTIFFTileSource.getAllTileSources(url, {
        logLatency: false
      });

      mppRef.current = tiffTileSources[0].dimensions.x;
      newViewer.open(tiffTileSources);
      setViewer(newViewer);
    };

    initializeViewer();

    return () => {
      if (viewer) {
        viewer.destroy();
        setViewer(null);
      }
    };
  }, [url]);

  return (
    <div className="w-full max-w-[800px] mx-auto px-4">
      <div
        id="viewer"
        ref={viewerRef}
        className="relative w-full aspect-[4/3] rounded-md shadow-md overflow-hidden"
        style={{ border: "thin" }}
      >
        {/* Map Legends */}
        {visibleMap === "mitosesMap" && (
          <div className="absolute bottom-2 right-2 bg-white p-2 rounded shadow-md">
            <div className="flex items-center space-x-2">
              <Square className="text-green-500" size={20} />
              <span className="text-sm font-medium">Highest Mitosis Density</span>
            </div>
          </div>
        )}

        {visibleMap === "imnMap" && (
          <div className="absolute bottom-2 right-2 bg-white p-2 rounded shadow-md">
            {Object.entries(CLASS_COLORS).map(([key, color]) => (
              <div key={key} className="flex items-center space-x-2">
                <Circle style={{ color }} className="fill-current" size={16} />
                <span className="text-sm font-medium">
                  {key === '0' ? 'B Cell' : key === '1' ? 'CD4' : 'CD8'}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Controls and Feedback Form */}
      {['mitosesMap', 'imnMap'].includes(visibleMap) && (
        <div className="mt-4">
          <div className="flex flex-col lg:flex-row gap-4">
            <div className="w-full lg:w-2/3">
              {filteredObjectData.length > 0 ? (
                <FeedBackFormMitosis
                  slideParameters={slideParameters}
                  resultsFilename="mitoses_results.csv"
                  viewer={viewer}
                  mitosesData={filteredObjectData}
                />
              ) : (
                <div className="p-4 bg-gray-100 rounded">
                  No data available for the current threshold.
                </div>
              )}
            </div>
            <div className="w-full lg:w-1/3">
              <SliderField
                label="Confidence Threshold"
                min={0}
                max={1}
                step={0.01}
                value={threshold}
                onChange={setThreshold}
                className="w-full"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewerComp;