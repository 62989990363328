import { Heading } from "@aws-amplify/ui-react";
import { SubjectSelectField } from "../../components/SubjectSelectField";
import UploadSlideForm from "../../feature/UploadSlideForm";
import { useNavigate, useParams } from "react-router-dom";
import WhiteCard from "../../components/WhiteCard";
import { View, Text } from '@aws-amplify/ui-react';

export default function UploadSlide() {
    const { subjectId } = useParams();
    const navigate = useNavigate();

    const selectedSubjectId = subjectId ? subjectId : "";
    const showUploadForm = selectedSubjectId !== "";
    const handleSubjectSelection = (id: string) => {
        if (!id) { navigate(`/upload`); }
        else { navigate(`/subjects/${id}/upload`); }
    };
    return (
        <>

            <Heading level={4}><strong>Upload a New Slide</strong></Heading>

            <WhiteCard>
                <SubjectSelectField
                    showAddButton={false}
                    selectedSubjectId={selectedSubjectId}
                    setSelectedSubjectId={handleSubjectSelection}
                />
                {showUploadForm && <>
                    <Heading color="#333"> Add New Slide Image </Heading>
                    <br></br>
                    <UploadSlideForm selectedSubjectId={selectedSubjectId} />
                    <View backgroundColor="rgba(0, 0, 0, 0.05)" padding="1rem" borderRadius="4px">
                        <Text fontSize="0.9rem" color="rgba(0, 0, 0, 0.6)">
                            Note: For MRSX files, please zip the .mrsx file and the same-named folder together before uploading.
                        </Text>
                    </View>
                </>}
                {!showUploadForm && <>
                    <Heading color="#333"> Select a subject to add an image </Heading>
                </>}


            </WhiteCard>
        </>

    );
}
