import React from 'react';

const WhiteCard = ({ children }) => {
  return (
    <div className="bg-white rounded-lg shadow-md w-full max-w-4xl p-4 sm:p-6">
      {children}
    </div>
  );
};

export default WhiteCard;