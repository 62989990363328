import React from 'react';
import { motion } from 'framer-motion';
import { XCircle, AlertTriangle } from 'lucide-react';
import { useRecoilValue } from "recoil";
import { userIdSelector } from "../recoil/uiState";
import { API, Auth } from 'aws-amplify';

interface DeletePopupProps {
  selectedRows: string[];
  subjectId: string | undefined;
  onClose: () => void;
}

const DeletePopup: React.FC<DeletePopupProps> = ({ selectedRows, subjectId, onClose }) => {
  const userId = useRecoilValue(userIdSelector);

  const deleteSlides = async (selectedRows: string[], subjectId: string | undefined) => {
    try {
      const credentials = await Auth.currentCredentials();
      const identityID = credentials.identityId;
      console.log(selectedRows);
      for (const slideId of selectedRows) {
        await API.del('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides/${slideId}`, {
          body: {
            SlideId: slideId,
            cognito_identity_id: identityID,
          },
        });
      }
    } catch (error) {
      console.error('Error deleting slides:', error);
      throw error;
    }
  };

  const handleDelete = async () => {
    try {
      await deleteSlides(selectedRows, subjectId);
      onClose();
    } catch (error) {
      console.error('Error deleting slides:', error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl"
      >
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <AlertTriangle className="text-yellow-500 mr-2" size={24} />
            <h2 className="text-xl font-bold text-gray-800">Delete Slides</h2>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <XCircle size={24} />
          </button>
        </div>
        <p className="text-gray-600 mb-6">
          Are you sure you want to delete {selectedRows.length} selected slide{selectedRows.length > 1 ? 's' : ''}? This action cannot be undone.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default DeletePopup;