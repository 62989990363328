import React from 'react';
import { Button } from "@aws-amplify/ui-react";
import { FileText, Database, Info } from 'lucide-react';

export interface Algorithm {
  id: number;
  title: string;
  images: string[];
  details: string;
  link: string;
  paperLink: string;
  datasetLink: string;
}

interface AlgorithmPopupProps {
  algorithm: Algorithm;
  onClose: () => void;
}

const AlgorithmPopup: React.FC<AlgorithmPopupProps> = ({ algorithm, onClose }) => {
  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  const formatText = (text: string) => {
    return text.split('\n').map((paragraph, index) => (
      <p key={index} className="mb-4 text-gray-700">
        {paragraph}
      </p>
    ));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={handleOverlayClick}>
      <div className="bg-white rounded-lg p-8 w-11/12 max-w-4xl max-h-[90vh] overflow-y-auto">
        <h3 className="text-2xl font-bold mb-6 text-center uppercase text-gray-800">
          {algorithm.title}
        </h3>
        <div className="flex flex-col items-center mb-6">
          {algorithm.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`${algorithm.title} - Image ${index + 1}`}
              className="w-full max-w-md mb-4 rounded-lg shadow-md"
            />
          ))}
        </div>
        <div className="text-left mb-6">
          {formatText(algorithm.details)}
        </div>

        <div className="flex flex-wrap justify-between items-center">
          <a
            href={algorithm.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 font-semibold flex items-center mb-2 sm:mb-0"
          >
            <FileText className="mr-2" size={20} />
            Read Paper
          </a>
          <a
            href={algorithm.datasetLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-600 hover:text-purple-800 font-semibold flex items-center mb-2 sm:mb-0"
          >
            <Database className="mr-2" size={20} />
            View Dataset
          </a>
          <Button
            onClick={() => alert('Request for more information sent!')}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center"
          >
            <Info className="mr-2" size={20} />
            Talk to us
          </Button>
          <Button onClick={onClose} className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AlgorithmPopup;