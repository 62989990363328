import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";

interface NavToggleProps {
  expand?: boolean;
  onChange?: () => void;
}

const SidebarNavToggle = ({ expand, onChange }: NavToggleProps) => {
  return (
    <button 
      onClick={onChange}
      className={`sidebar-toggle ${expand ? 'expanded' : ''}`}
    >
      <MdKeyboardArrowRight />
    </button>
  );
};

export default SidebarNavToggle;