import { API, Auth } from "aws-amplify";
import Papa from "papaparse";
// Sanitize filename by removing or replacing undesired characters
export const sanitizeFileNameWithSubjectID = async ({ file}: { file: File }): Promise<{ name: string }> => {
    let sanitizedFileName = '';
    sanitizedFileName = file.name.replace(/\s+/g, '_').replace(/[<>:"/\\|?*]+/g, '');
    return { name:sanitizedFileName };
};


export const generateThumbnail = (filepath: string) => {
    const url = `/generatethumbnail`;
    Auth.currentCredentials()
      .then(credentials => {
        const identityID = credentials.identityId;
        console.log('Initiating thumbnail generation for:', filepath);
        
        API.post('histopathologyAPI', url, {
          body: {
            slidePath: filepath,
            identityID: identityID
          }
        })
          .then(() => {
            console.log('Thumbnail generation initiated successfully. This may take a while.');
            // Optionally, you can show a message to the user indicating that the process has started
          })
          .catch(error => {
            console.error('Error initiating thumbnail generation:', error);
            // Handle the error appropriately (e.g., show an error message to the user)
          });
      })
      .catch(error => {
        console.error('Error retrieving credentials:', error);
        // Handle the error appropriately (e.g., show an error message to the user)
      });
    
    console.log('Thumbnail generation process started for:', filepath);
    return filepath;
  };
  

export const getUserID = async (refresh: boolean = false): Promise<string> => {
    if (!refresh) {
        const cachedUserID = sessionStorage.getItem('userID');
        if (cachedUserID) {
            return cachedUserID;
        }
    }
    try {
        console.log('getting user id');
        const response = await API.get('histopathologyAPI', '/users/current', {});
        console.log(response);
        const userID = response.id;
        sessionStorage.setItem('userID', userID);
        return userID;
    } catch (error) {
        console.error('Error fetching user ID:', error);
        throw error;
    }
};

export const postToPatientEndpoint = async (route: string, subjectId: string, jsonObject: any): Promise<any> => {
    let userID = await getUserID();
    try {
        const response = await API.post('histopathologyAPI', `/users/${userID}/subjects/${subjectId}/${route}`, { body: jsonObject });
        return response;
    } catch (error: any) {
        console.error('Error posting to endpoint:', error);
        throw error;
    }
};

export const getToPatientEndpoint = async (route: string, subjectId: string, jsonObject: any): Promise<any> => {
    let userID = await getUserID();
    try {
        const response = await API.get('histopathologyAPI', `/users/${userID}/subjects/${subjectId}/${route}`, { body: jsonObject });
        return response;
    } catch (error: any) {
        console.error('Error posting to endpoint:', error);
        throw error;
    }
};
interface SlideParameters {
  userId: string;
  subjectId: string;
  slideId: string;
  level: 'public' | 'protected' | 'private';
  slidePath: string;
}

export interface ObjectCSVItem {
  coords_x: number;
  coords_y: number;
  conf: number;
  class: string;
}

export const fetchObjectData = async (slideParameters: SlideParameters, resultsFilename: string): Promise<ObjectCSVItem[]> => {
  try {
    const presignedUrlResponse = await API.get('histopathologyAPI', '/presignedUrl_get', {
      queryStringParameters: {
        bucket: 'svs-to-compute',
        key: `${slideParameters.slidePath}/${resultsFilename}`
      }
    });
    const url = presignedUrlResponse.url;
    const response = await fetch(url as string);
    const csvText = await response.text();
    console.log(url);
    return new Promise((resolve, reject) => {
      Papa.parse<ObjectCSVItem>(csvText, {
        header: true,
        dynamicTyping: true,
        complete: (results) => {
          resolve(results.data);
        },
        error: (error: any) => {
          reject(error);
        }
      });
    });
  } catch (error) {
    console.error("Error fetching mitoses data:", error);
    throw error;
  }
};

export const fetchMitosesBBox = async (slideParameters: SlideParameters): Promise<number[] | null> => {
  if (slideParameters.userId && slideParameters.subjectId && slideParameters.slideId) {
    try {
      const response = await API.get('histopathologyAPI', `/users/${slideParameters.userId}/subjects/${slideParameters.subjectId}/slides/${slideParameters.slideId}/inferences`, {}); 
      const mitInference = response.data.find((inference: any) => inference.inference_type === 'MIT');
      
      if (mitInference && mitInference.results && mitInference.results.highest_density_bbox_coordinates) {
        return mitInference.results.highest_density_bbox_coordinates;
      }
    } catch (error) {
      console.error('Error fetching inferences:', error);
    }
  }
  return null;
};