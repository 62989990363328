import React, { useState, useCallback } from "react";
import { Button, SelectField } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { getUserID } from "../api/api";
import { AddSubjectForm } from "./AddSubjectForm";
import { useQuery } from "@tanstack/react-query";

type Subject = { id: string; name: string };

type SubjectSelectFieldProps = {
  showAddButton?: boolean;
  selectedSubjectId: string;
  setSelectedSubjectId: (id: string) => void;
};

const fetchSubjects = async (): Promise<Subject[]> => {
  const userID = await getUserID();
  const response = await API.get("histopathologyAPI", `/users/${userID}/subjects`, {});
  return response.data;
};

export const SubjectSelectField: React.FC<SubjectSelectFieldProps> = ({
  selectedSubjectId,
  setSelectedSubjectId,
  showAddButton = true
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data: subjects = [], isLoading, refetch } = useQuery({
    queryKey: ['subjects'],
    queryFn: fetchSubjects,
  });

  const handleAddSubject = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleCloseForm = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubjectAdded = useCallback(() => {
    refetch();
    handleCloseForm();
  }, [refetch, handleCloseForm]);

  return (
    <div className="mb-8">
      <SelectField
        label="Selected subject"
        placeholder="Select a subject"
        value={selectedSubjectId}
        onChange={(e) => setSelectedSubjectId(e.target.value)}
        disabled={isLoading}
      >
        {subjects.map((subject: Subject) => (
          <option key={subject.id} value={subject.id}>
            {`${subject.name} (ID: ${subject.id})`}
          </option>
        ))}
      </SelectField>
      {showAddButton && (
        <Button className="mt-4" onClick={handleAddSubject}>
          Add New Subject
        </Button>
      )}

      <AddSubjectForm
        isOpen={isOpen}
        onClose={handleCloseForm}
        onSubjectAdded={handleSubjectAdded}
      />
    </div>
  );
};