import { useEffect, useState } from "react"
import { Flex, SelectField } from "@aws-amplify/ui-react";
import WhiteCard from "../../components/WhiteCard";
import { API} from "aws-amplify";
import ViewerComp from "../../feature/ViewerComp";
import InferencesSummaryTable from "../../feature/InferencesSummaryTable";

export default function Tech() {
    const [slideURL, setSlideURL] = useState<string>("");
    const [selectedSlide, setSelectedSlide] = useState('');
    const [selectedSlideID, setSelectedSlideID] = useState('');
    const [selectedSlideFolder, setSelectedSlideFolder] = useState('');
    const [visibleMap, setVisibleMap] = useState<string | null>(null);
    const slides = [
        {
            id: '30',
            label: 'Soft-Tissue Sarcoma -1, Site: Connective, subcutaneous and other soft tissues',
            value: 'Demo1/Demo1.tif',
            folder: 'Demo1'
        },
        {
            id: '31',
            label: 'Soft-Tissue Sarcoma -2, Site: Retroperitoneum and peritoneum',
            value: 'Demo2/Demo2.tif',
            folder: 'Demo2'
        },
        // Add more slides as needed
    ];
    
    useEffect(() => {

        const fetchSlideURL = async () => {
            const presignedUrlResponse = await API.get('histopathologyAPI', '/presignedUrl_get', {
                queryStringParameters: { 
                    bucket: 'demo-svs',
                    key: selectedSlide 
                }
              });
            const url = presignedUrlResponse.url;
            console.log(url);
            setSlideURL(url);
        }

        if (selectedSlide !== "") {
            fetchSlideURL();
        }
        else {
            setSlideURL('');
        }
    }, [selectedSlide]);

    const handleSlideChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setSelectedSlide(selectedValue);

        // Find the slide object based on the selected value
        const selectedSlideObject = slides.find(slide => slide.value === selectedValue);

        // Set the selectedSlideID based on the found slide object
        const selectedSlideID = selectedSlideObject ? selectedSlideObject.id : '';
        setSelectedSlideID(selectedSlideID);
        const selectedSlideFolder = selectedSlideObject ? selectedSlideObject.folder : '';
        setSelectedSlideFolder(selectedSlideFolder);
        setVisibleMap(null);
    };


    return (


            <div className="flex flex-col items-center bg-white px-4 py-16">
             <h1 className="text-4xl font-bold text-center mb-12">AI Demo</h1>
            

            <WhiteCard>
                <SelectField
                    label="Demo slide available:"
                    onChange={handleSlideChange}
                    placeholder="Select a slide"
                    value={selectedSlide}>
                    {slides.map((slide) => (
                        <option key={slide.value} value={slide.value}>
                            {slide.label}
                        </option>
                    ))}
                </SelectField>
                <Flex direction="column" gap="2rem">
                    {slideURL && <ViewerComp
                        url={slideURL}
                        visibleMap={visibleMap}
                        slideParameters={{
                            userId: '1',
                            subjectId: '0',
                            slideId: selectedSlideID,
                            level: 'public',
                            slidePath: selectedSlideFolder
                        }}
                    />}
                </Flex>
                <Flex direction="column" paddingBottom="20px"></Flex>
                <InferencesSummaryTable
                    userId={'1'}
                    subjectId={'0'}
                    slideId={selectedSlideID}
                    visibleMap={visibleMap}
                    setVisibleMap={setVisibleMap}
                />
            </WhiteCard>
            </div>
    );
}
