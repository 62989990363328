import { Authenticator, Loader, WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import { RecoilRoot } from "recoil";
import { Suspense, useEffect } from "react";
import { ThemeProvider, Theme } from '@aws-amplify/ui-react';
import './index.css';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Authed pages
import Dashboard from "./pages/authed/Dashboard";
import Tech from "./pages/public/Technology";
import InferenceDetail from "./pages/authed/InferenceDetail";
import Slides from "./pages/authed/Slides";
import SlideDetail from "./pages/authed/SlideDetail";
import UploadSlide from "./pages/authed/UploadSlide";
import Subjects from "./pages/authed/Subjects";
import SubjectDetail from "./pages/authed/SubjectDetail";

// Public pages
import Teams from "./pages/public/Team"
import RequestDemo from "./pages/public/RequestDemo";
import FrontPage from "./pages/public/FrontPage";
import Vision from "./pages/public/Vision";
import Career from "./pages/public/Career";
import Collection from "./pages/public/Collection"

interface Props extends WithAuthenticatorProps {
    isPassedToWithAuthenticator: boolean;
}

// Create a client
const queryClient = new QueryClient();

function App({ isPassedToWithAuthenticator, signOut, user }: Props) {
    const location = useLocation();

    useEffect(() => {
        window.gtag('config', 'G-V432S7MTRW', {
            page_path: location.pathname + location.search
        });
    }, [location]);
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <Authenticator.Provider>
                    <RecoilRoot>
                        <Suspense fallback={<Loader />}>
                            <Routes>
                                <Route path="/" element={<Layout />}>
                                    <Route index element={<FrontPage />} />
                                    <Route path="vision" element={<Vision />} />
                                    <Route path="tech" element={<Tech />} />
                                    <Route path="demo" element={<RequestDemo />} />
                                    <Route path="team" element={<Teams />} />
                                    <Route path="career" element={<Career />} />
                                    <Route path="collection" element={<Collection />} />
                                    <Route path="dashboard" element={
                                        <Authenticator>
                                            <Dashboard />
                                        </Authenticator>
                                    } />
                                    <Route path="upload" element={
                                        <Authenticator>
                                            <UploadSlide />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/upload" element={
                                        <Authenticator>
                                            <UploadSlide />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects" element={
                                        <Authenticator>
                                            <Subjects />
                                        </Authenticator>
                                    } />
                                    <Route path="slides" element={
                                        <Authenticator>
                                            <Slides />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/slides" element={
                                        <Authenticator>
                                            <Slides />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/view" element={
                                        <Authenticator>
                                            <SubjectDetail />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/slides/:slideId/view" element={
                                        <Authenticator>
                                            <SlideDetail />
                                        </Authenticator>
                                    } />
                                    <Route path="subjects/:subjectId/slides/:slideId/inferences/:inferenceId/view" element={
                                        <Authenticator>
                                            <InferenceDetail />
                                        </Authenticator>
                                    } />
                                    <Route path="*" element={<NoMatch />} />
                                </Route>
                            </Routes>
                        </Suspense>
                    </RecoilRoot>
                </Authenticator.Provider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;

function NoMatch() {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center'
        }}>
            <h2>You are </h2>
            <img
                src={`${process.env.PUBLIC_URL}/404.png`} alt="Not Found"
                width="300"
                height="300"
            />
        </div>
    );
}

const theme: Theme = {
    name: 'my-theme',
    tokens: {
        components: {
            authenticator: {
                form: {
                    padding: { value: '10px' },
                },
            },
        },
        fonts: {
            default: {
                variable: { value: 'Montserrat, sans-serif' },
                static: { value: 'Montserrat, sans-serif' },
            },
        },
    },
};