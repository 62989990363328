// inferenceUtils.ts
import { API, Auth, Storage } from "aws-amplify";
import JSZip from "jszip";
import { saveAs } from "file-saver";
export async function submitInference(
  userId: string,
  subjectId: string,
  slideIds: string[],
  selectedInference: { InferenceType: string } | undefined,
  modelVersion: string
) {
  const credentials = await Auth.currentCredentials();
  const identityID = credentials.identityId;
  const session = await Auth.currentSession(); // Get the current session
  const idToken = session.getIdToken().getJwtToken(); // Get the ID Token

  const responses = [];
  for (let i = 0; i < slideIds.length; i++) {
    const slideId = slideIds[i];
    console.log('here');
    const response = await API.post(
      "histopathologyAPI",
      `/users/${userId}/subjects/${subjectId}/slides/${slideId}/inferences`,
      {
        body: {
          modelVersion: modelVersion,
          inference_type: selectedInference?.InferenceType,
          cognito_identity_id: identityID,
        },
        headers: {
          Authorization: idToken,
        },
      }
    );
    console.log("Post inferences response:", response);
    responses.push(response);
  }

  return responses;
}  

export async function downloadAIResults(
  userId: string,
  subjectId: string,
  slideIds: string[]
) {
  const zip = new JSZip();
  const credentials = await Auth.currentCredentials();
  const identityID = credentials.identityId;
  const session = await Auth.currentSession();
  const idToken = session.getIdToken().getJwtToken();

  for (const slideId of slideIds) {
    // Define the paths for the files you want to download
    const filePaths = [
      `subjects/${subjectId}/slides/${slideId}/mitoses_results.csv`,
      `subjects/${subjectId}/slides/${slideId}/IMN_results.csv`,
      `subjects/${subjectId}/slides/${slideId}/slide_mitoses.geojson`,
      `subjects/${subjectId}/slides/${slideId}/slide_bbox.geojson`
    ];

    for (const filePath of filePaths) {
      try {
        // Get the presigned URL for each file directly
        const presignedUrlResponse = await API.get('histopathologyAPI', '/presignedUrl_get', { 
          queryStringParameters: { 
            bucket: 'svs-to-compute',
            key: filePath 
          } 
        });
        
        const presignedUrl = presignedUrlResponse.url;
        console.log('presignedUrl', presignedUrl);
        // Fetch the file using the presigned URL
        const fileResponse = await fetch(presignedUrl);
        const downloadedFile = await fileResponse.blob();

        // Add the file to the zip archive
        zip.file(filePath, downloadedFile);
      } catch (error) {
        console.warn(`File not found: ${filePath}. Skipping...`);
      }
    }
  }

  // Generate the zip file and prompt download
  const zipBlob = await zip.generateAsync({ type: "blob" });
  saveAs(zipBlob, "ai_results.zip");
}