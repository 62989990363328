import { Auth } from 'aws-amplify';

const awsConfig = {
    Auth: {
      region: 'eu-west-2',
      userPoolId: 'eu-west-2_yD3R1RSwa',
      userPoolWebClientId: '4qc6a23p90vchb6hp5h9kv63v4',
      identityPoolId: 'eu-west-2:1444e949-3a0f-4e0a-b0f7-62456b09bc58',
    },
    Storage: {
      AWSS3: {
        bucket: 'histopathology-slides',
        region: 'eu-west-2',
      }
    },
    API: {
      endpoints: [
        {
          name: 'histopathologyAPI',
          endpoint: 'https://octopath.ai/api',
          custom_header: async () => {
            try {
              // We'll need to import Auth at the top if not already imported
              const session = await Auth.currentSession();
              const token = session.getIdToken().getJwtToken();
              return { Authorization: `Bearer ${token}` };
            } catch (error) {
              console.error('Error getting authentication token:', error);
              return {};
            }
          }
        }
      ]
    }
  };
  
  export default awsConfig;