import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Button, Flex, TextField, SelectField, TextAreaField } from "@aws-amplify/ui-react";
import { toast, Toaster } from 'sonner';
import WhiteCard from "../../components/WhiteCard";
import { countries } from 'countries-list';
import { API } from 'aws-amplify';

const formSchema = z.object({
  name: z.string().min(2, { message: "Name must be at least 2 characters." }),
  email: z.string().email({ message: "Please enter a valid email address." }),
  organization: z.string().min(2, { message: "Organization name must be at least 2 characters." }),
  jobPosition: z.string().min(2, { message: "Job position must be at least 2 characters." }),
  country: z.string().min(2, { message: "Please select a country." }),
  researchArea: z.string().min(2, { message: "Please specify your research area." }),
  message: z.string().optional(),
});

const RequestDemo = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (data) => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('email_type', 'chat_request');
      Object.entries(data).forEach(([key, value]) => {
        formDataToSend.append(key, value);
      });

      const response = await API.post("histopathologyAPI", "/send-email", {
        body: formDataToSend,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Response:', response);
      toast.success("Chat request submitted successfully!");
      reset();
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to submit chat request. Please try again.');
    }
  };

  const countryList = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: country.name
  }));

  return (
    <div className="flex flex-col items-center bg-white px-4 py-16">
      <Toaster position="top-right" />
      <h1 className="text-4xl font-bold text-center mb-12">Chat with us</h1>
      <WhiteCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap="medium">
            <TextField label="Name" placeholder='John Doe' {...register("name")} errorMessage={errors.name?.message} />
            <TextField label="Email" type="email" placeholder='john.doe@example.com' {...register("email")} errorMessage={errors.email?.message} />
            <TextField label="Organization" placeholder="Cancer Research Institute" {...register("organization")} errorMessage={errors.organization?.message} />
            <TextField label="Job Position" placeholder="Oncologist" {...register("jobPosition")} errorMessage={errors.jobPosition?.message} />
            <SelectField label="Country" {...register("country")} errorMessage={errors.country?.message} options={countryList} labelHidden={false} descriptiveText="Select your country">
              {countryList.map((country) => (
                <option key={country.value} value={country.value}>
                  {country.label}
                </option>
              ))}
            </SelectField>
            <TextField label="Research Area" placeholder="e.g., Breast Cancer, Immunotherapy" {...register("researchArea")} errorMessage={errors.researchArea?.message} />
            <TextAreaField label="Tell us briefly what would you be interested in (Optional)" {...register("message")} errorMessage={errors.message?.message} />
            <Button type="submit" variation="primary">Submit Request</Button>
          </Flex>
        </form>
      </WhiteCard>
    </div>
  );
};

export default RequestDemo;