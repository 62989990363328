import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { API } from "aws-amplify";
import { sanitizeFileNameWithSubjectID } from "../api/api";
import { Check } from 'lucide-react';

interface UploadFile extends File {
    id: string;
}

interface UploadProgress {
    [key: string]: number;
}

interface UploadSlideFormProps {
    selectedSubjectId: string;
}

const UploadSlideForm: React.FC<UploadSlideFormProps> = ({ selectedSubjectId }) => {
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [progress, setProgress] = useState<UploadProgress>({});
    const [completedUploads, setCompletedUploads] = useState<Set<string>>(new Set());
    const [isUploading, setIsUploading] = useState(false);
    const [activeUploads, setActiveUploads] = useState<Set<string>>(new Set());

    useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            if (isUploading) {
                event.preventDefault();
                event.returnValue = 'You have unfinished uploads. Are you sure you want to leave?';
                return event.returnValue;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isUploading]);

    useEffect(() => {
        setIsUploading(activeUploads.size > 0);
    }, [activeUploads]);

    const uploadFile = async (file: UploadFile) => {
        setActiveUploads(prev => new Set(prev).add(file.id));
        try {
            const sanitizedFile = await sanitizeFileNameWithSubjectID({ file });
            console.log(sanitizedFile.name);
            const presignedUrlResponse = await API.get('histopathologyAPI', '/presignedUrl_put', {
                queryStringParameters: { 
                    bucket: 'temp',
                    key: sanitizedFile.name 
                }
            });
            const presignedUrl = presignedUrlResponse.url;
            console.log(presignedUrl)
            await new Promise<void>((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('PUT', presignedUrl, true);
                xhr.setRequestHeader('Content-Type', file.type);

                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = Math.round((event.loaded / event.total) * 100);
                        setProgress(prev => ({ ...prev, [file.id]: percentComplete }));
                    }
                };

                xhr.onload = async () => {
                    if (xhr.status === 200) {
                        setProgress(prev => ({ ...prev, [file.id]: 100 }));
                        setCompletedUploads(prev => new Set(prev).add(file.id));

                        try {
                            await API.post('histopathologyAPI', '/generatethumbnail', {
                                body: { name: sanitizedFile.name, subjectID: selectedSubjectId }
                            });
                            console.log(`Thumbnail generation initiated for ${sanitizedFile.name}`);
                        } catch (thumbnailError) {
                            console.error(`Failed to initiate thumbnail generation for ${sanitizedFile.name}:`, thumbnailError);
                        }

                        resolve();
                    } else {
                        reject(new Error(`Upload failed: ${xhr.statusText}`));
                    }
                };

                xhr.onerror = () => {
                    reject(new Error('Upload failed'));
                };

                xhr.send(file);
            });
        } catch (error) {
            console.error('Upload failed:', error);
            setProgress(prev => ({ ...prev, [file.id]: 0 }));
        } finally {
            setActiveUploads(prev => {
                const newSet = new Set(prev);
                newSet.delete(file.id);
                return newSet;
            });
        }
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
        const newFiles = acceptedFiles.map(file => Object.assign(file, { id: Math.random().toString(36).substr(2, 9) }));
        setFiles(prevFiles => [...prevFiles, ...newFiles].slice(0, 100) as UploadFile[]);
        newFiles.forEach(file => uploadFile(file as UploadFile));
    }, [selectedSubjectId]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxFiles: 100,
        accept: {
            'image/svs': ['.svs'],
            'application/zip': ['.mrxs'],
            'image/ndpi': ['.ndpi'],
            'image/tiff': ['.tif'],
            'image/czi': ['.czi'],
            'application/dicom': ['.dcm']
        }
    });

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
            {isUploading && (
                <div className="mb-4 p-3 bg-yellow-100 border border-yellow-400 text-yellow-700 rounded">
                    <p>Warning: Leaving this page will cancel any ongoing uploads.</p>
                </div>
            )}
            <div
                {...getRootProps()}
                className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors ${
                    isDragActive ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-blue-500'
                }`}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p className="text-blue-500">Drop the files here ...</p>
                ) : (
                    <p className="text-gray-500">Drag and drop files here, or click to select files</p>
                )}
                <p className="text-sm text-gray-400 mt-2">
                    Accepted formats: .svs, .mrxs (zip), .ndpi, .tif, .czi, .dcm
                </p>
            </div>
            {files.length > 0 && (
                <ul className="mt-6 space-y-3">
                    {files.map((file) => (
                        <li key={file.id} className="bg-gray-50 rounded-lg p-3 flex items-center">
                            <img src="/file-document-svgrepo-com.svg" alt="File icon" className="w-6 h-6 mr-3" />
                            <span className="flex-grow truncate">{file.name}</span>
                            <div className="w-24 bg-gray-200 rounded-full h-2.5 ml-3">
                                <div
                                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                                    style={{ width: `${progress[file.id] || 0}%` }}
                                ></div>
                            </div>
                            <span className="ml-3 w-12 text-right text-sm text-gray-500">
                                {`${Math.round(progress[file.id] || 0)}%`}
                            </span>
                            {completedUploads.has(file.id) && (
                                <Check className="w-5 h-5 text-green-500 ml-2" />
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default UploadSlideForm;