import React, { useState } from "react";
import { baseConfig } from "../../config";
import { Text, Link } from "@aws-amplify/ui-react";
import Logo from "../Logo";
import HeaderNav from "./HeaderNav";

type HeaderProps = {
  loggedIn: boolean;
  isPublicPage: boolean;
  onSignOut: () => void;
};

const Header_public: React.FC<HeaderProps> = ({ loggedIn, isPublicPage, onSignOut}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigationLinks = [
    { href: "/", label: "HOME" },  // Mobile-only home link
    { href: "/dashboard", label: "DASHBOARD" },  // Mobile-only home link
    { href: "/vision", label: "VISION" },
    { href: "/team", label: "TEAM" },
    { href: "/career", label: "CAREER" },
    { href: "/tech", label: "AI DEMO" },
    { href: "/demo", label: "CHAT WITH US!" },
    { href: "/collection", label: "COLLECTION" },
  ];

  const desktopLinks = navigationLinks.slice(2); // Skip HOME for desktop

  return (
    <>
      <header className="fixed top-0 left-0 right-0 z-20 bg-white w-full h-[60px]">
        <div className="flex items-center justify-between px-4 h-full">
          <div className="flex items-center space-x-3">
            <div className="w-8 h-8">
              <div className="block md:hidden cursor-pointer" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <Logo />
              </div>
              <Link href="/" className="hidden md:block">
                <Logo />
              </Link>
            </div>
            <span className="text-sm uppercase font-medium">{baseConfig.headerText}</span>
          </div>
          
          {isPublicPage && (
            <nav className="hidden md:flex gap-4 text-sm font-medium">
              {desktopLinks.map((link) => (
                <Text key={link.href}>
                  <Link href={link.href} className="hover:text-blue-600 transition-colors px-2">
                    {link.label}
                  </Link>
                </Text>
              ))}
            </nav>
          )}
          
          <div className="flex items-center">
            <HeaderNav loggedIn={loggedIn} onSignOut={onSignOut} />
          </div>
        </div>
      </header>

      <div 
        className={`md:hidden fixed inset-y-0 right-0 w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-30 ${
          isMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-6">
          <div className="mb-8">
            <Logo />
          </div>
          <nav className="space-y-1">
            {navigationLinks.map((link) => (
              <div key={link.href}>
                <Link 
                  href={link.href}
                  className="block py-3 text-sm font-medium hover:text-blue-600 transition-colors"
                >
                  {link.label}
                </Link>
                <div className="h-[1px] bg-gray-200" />
              </div>
            ))}
          </nav>
        </div>
      </div>

      {isMenuOpen && (
        <div 
          className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setIsMenuOpen(false)}
        />
      )}
    </>
  );
};

export default Header_public;