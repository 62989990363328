import React from 'react';
import { motion } from 'framer-motion';
import WhiteCard from "../../components/WhiteCard";

const images = [
  { src: "Heart.png", title: "Heartfelt Cell" },
  { src: "Fish.png", title: "Fishy Cell" },
  { src: "Horror.png", title: "Spooky Cell" },
  { src: "Heart2.png", title: "Love Cell" },
  { src: "SadFace.png", title: "Melancholy Cell" },
  { src: "Angel.png", title: "Heavenly Cell" }
];

export default function Collection() {
  return (
    <div className="min-h-screen bg-white py-12 px-4">
     
        <h2 className="text-4xl font-bold text-center mb-8 text-gray-800">Our Collection</h2>
        <div className="max-w-6xl mx-auto flex justify-center items-center">
        <WhiteCard>
          <div className="flex flex-col items-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
              {images.map((image, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="flex flex-col items-center"
                >
                  <img 
                    src={image.src} 
                    alt={image.title} 
                    className="w-40 h-40 object-cover rounded-lg shadow-md transition-all duration-300 hover:shadow-xl"
                  />
                  <p className="mt-2 text-sm font-medium text-gray-600">{image.title}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </WhiteCard>
      </div>
    </div>
  );
}