import { Menu, MenuButton, MenuItem, Alert, AlertVariations, Heading } from "@aws-amplify/ui-react";
import { ReactComponent as NeuralNetworkIcon } from '../../neural-network-icon.svg';
import { MdFileDownload, MdDelete } from 'react-icons/md';
import { useState } from "react";
import { submitInference, downloadAIResults } from "../../api/inferenceUtils";
import DeletePopup from '../../components/DeletePopup';
import { TableHeaderProps } from "./types";

const TableHeader = ({
    selectedRows,
    userId,
    subjectId,
    fetchSlides,
    setSelectAll,
    setSelectedRows
}: TableHeaderProps) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState<AlertVariations>('info');
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

    const handleSubmitInference = async (inferenceType: string) => {
        try {
            await submitInference(userId, subjectId, selectedRows, { InferenceType: inferenceType }, "stable");
            setAlertMessage(`Inference ${inferenceType} submitted for Slide Ids ${selectedRows.join(", ")}`);
            setAlertType('success');
            setShowAlert(true);
        } catch (error) {
            setAlertMessage('Error submitting inference');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    const isDisabled = selectedRows.length === 0;

    const handleMenuItemClick = (action: () => void) => {
        if (!isDisabled) {
            action();
        }
    };

    return (
        <>
            <div className="flex flex-col sm:flex-row items-center justify-between gap-4 sm:gap-0 mb-4 w-full">
                <div className="flex items-center gap-2 w-full sm:w-auto">
                    <Menu menuAlign="start" trigger={
                        <MenuButton variation="menu" disabled={isDisabled}>
                            <div className="avatar">
                                <NeuralNetworkIcon width={"1em"} height={"1em"} fill={'currentcolor'} strokeWidth={0} />
                            </div>
                        </MenuButton>
                    }>
                        {!isDisabled && (
                            <>
                                <MenuItem onClick={() => handleMenuItemClick(() => handleSubmitInference("MIT"))}>
                                    Mitoses
                                </MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick(() => handleSubmitInference("IMN"))}>
                                    Lymphocytes and Macrophages
                                </MenuItem>
                            </>
                        )}
                    </Menu>
                    <Menu menuAlign="start" trigger={
                        <MenuButton variation="menu" disabled={isDisabled}>
                            <div className="avatar">
                                <MdFileDownload />
                            </div>
                        </MenuButton>
                    }>
                        {!isDisabled && (
                            <MenuItem onClick={() => handleMenuItemClick(() => downloadAIResults(userId, subjectId, selectedRows))}>
                                Download AI results
                            </MenuItem>
                        )}
                    </Menu>
                    <Menu menuAlign="start" trigger={
                        <MenuButton variation="menu" disabled={isDisabled}>
                            <div className="avatar">
                                <MdDelete />
                            </div>
                        </MenuButton>
                    }>
                        {!isDisabled && (
                            <MenuItem onClick={() => handleMenuItemClick(() => setIsDeleteConfirmationOpen(true))}>
                                Delete slides
                            </MenuItem>
                        )}
                    </Menu>
                </div>
            </div>
            {isDeleteConfirmationOpen && (
                <DeletePopup
                    selectedRows={selectedRows}
                    subjectId={subjectId}
                    onClose={() => {
                        setSelectedRows([]);
                        setSelectAll(false);
                        setIsDeleteConfirmationOpen(false);
                        fetchSlides();
                    }}
                />
            )}
            {showAlert && (
                <Alert
                    variation={alertType}
                    isDismissible={true}
                    hasIcon={true}
                    heading=""
                    onDismiss={() => setShowAlert(false)}
                >
                    {alertMessage}
                </Alert>
            )}
        </>
    );
};

export default TableHeader;