import React, { useState, useEffect, useCallback, useRef } from "react";
import Papa from "papaparse";
import { debounce } from "lodash";
import { API, Storage } from "aws-amplify";
import { Flex, Button, RadioGroupField, Radio } from "@aws-amplify/ui-react";

const OpenSeadragon = window.OpenSeadragon;

const FeedBackFormMitosis = ({ slideParameters, resultsFilename, viewer, mitosesData }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedClassification, setSelectedClassification] = useState("");

    useEffect(() => {
        // Check if the current mitosis has a classification
        const currentMitosis = mitosesData[currentIndex];
        setSelectedClassification(currentMitosis?.classification || "");
    }, [currentIndex, mitosesData]);

    const handleClassificationChange = (newValue) => {
        setSelectedClassification(newValue);

        // Update the mitosis classification in the original array
        const updatedMitoses = [...mitosesData];
        updatedMitoses[currentIndex] = { ...updatedMitoses[currentIndex], classification: newValue };
        setMitoses(updatedMitoses); // Assuming you have a method to update the state that holds the mitoses array
    };

    const navigateToMitosis = (index) => {
        const mitosis = mitosesData[index];
        if (mitosis && viewer) {
            const viewportPoint = viewer.viewport.imageToViewportCoordinates(mitosis.coords_x, mitosis.coords_y);
            viewer.viewport.panTo(viewportPoint, true);
            viewer.viewport.zoomTo(viewer.viewport.getMaxZoom(), viewportPoint, true);
        }
    };

    const handleNavigation = useCallback((direction) => {
        let newIndex = currentIndex + (direction === "next" ? 1 : -1);
        if (newIndex < 0) newIndex = mitosesData.length - 1;
        else if (newIndex >= mitosesData.length) newIndex = 0;
        setCurrentIndex(newIndex);
        navigateToMitosis(newIndex);
    }, [currentIndex, mitosesData]); // Dependencies


    useEffect(() => {
        const handleKeyDown = (event) => {
            switch (event.key) {
                case "ArrowLeft":
                    handleNavigation("prev");
                    break;
                case "ArrowRight":
                    handleNavigation("next");
                    break;
                case "1":
                    handleClassificationChange("Mitose");
                    break;
                case "2":
                    handleClassificationChange("Not-Mitose");
                    break;
                case "3":
                    handleClassificationChange("Equivocal");
                    break;

                default:
                    break;
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleNavigation]);

    const debouncedExportAndUploadCSV = useCallback(debounce(async () => {
        const csv = Papa.unparse({
            data: mitosesData.current,
            fields: ["coords_x", "coords_y", "conf"]
        });
        const blob = new Blob([csv], { type: "text/csv" });
        const filePath = `${slideParameters.slidePath}/${resultsFilename}`;
        try {
            // Upload the CSV file to S3
            await Storage.put(filePath, blob, {
                level: "protected", // or 'public' or 'private', depending on your needs
                contentType: "text/csv"
            });
            console.log("CSV uploaded successfully");
        } catch (error) {
            console.error("Error uploading CSV to S3:", error);
        }

    }, 5000), []); // 5000 milliseconds = 5 seconds


    useEffect(() => {
        if (mitosesData.length > 0) {
            debouncedExportAndUploadCSV();
        }

        // Cleanup function to cancel the debounced call if the component unmounts
        return () => {
            debouncedExportAndUploadCSV.cancel();
        };
    }, [mitosesData, debouncedExportAndUploadCSV]);


    return (
        <Flex direction="row" alignItems="center" justifyContent="left" gap="0px">
            <Button onClick={() => handleNavigation("prev")}>Previous</Button>
            <Button onClick={() => handleNavigation("next")}>Next</Button>

            {/*<RadioGroupField
                legend="Classification"
                name="classification"
                variation="outlined"
                direction="row"
                value={selectedClassification}
                onChange={(e) => handleClassificationChange(e.target.value)}
            >
                <Radio value="Mitose">Mitose</Radio>
                <Radio value="Not-Mitose">Not-Mitose</Radio>
                <Radio value="Equivocal">Equivocal</Radio>
            </RadioGroupField>*/}

        </Flex>
    );
};

export default FeedBackFormMitosis;
