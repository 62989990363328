import React, { useState } from 'react';
import { Flex, Card, Heading, Text, Button, Input } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { toast, Toaster } from 'sonner';
import WhiteCard from "../../components/WhiteCard";
import { jobPositions, JobPosition } from "../../data/jobPositions";

export default function Career() {
  const [selectedPosition, setSelectedPosition] = useState<JobPosition | null>(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    cv: null as File | null,
    description: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCardClick = (position: JobPosition) => {
    setSelectedPosition(position);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setFormData((prevData) => ({
      ...prevData,
      cv: file || null,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!formData.cv) {
      toast.error('Please upload your CV.');
      setIsSubmitting(false);
      return;
    }
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('email_type', 'career_application');
      formDataToSend.append('name', formData.name);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('description', formData.description);
      formDataToSend.append('cv', formData.cv);
  
      const response = await API.post("histopathologyAPI", "/send-email", {
        body: formDataToSend,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Response:', response);
      toast.success('Application submitted successfully!');
      setFormData({
        name: "",
        email: "",
        cv: null,
        description: "",
      });
      setSelectedPosition(null);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to submit application. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderList = (items: string[] | string | number) => {
    if (Array.isArray(items)) {
      return (
        <ul className="list-disc list-inside space-y-1">
          {items.map((item, index) => (
            <li key={index} className="text-gray-700">{item}</li>
          ))}
        </ul>
      );
    }
    return <p className="text-gray-700">{items}</p>;
  };

  return (
    <div className="flex flex-col items-center min-h-screen bg-white py-16 px-4 sm:px-6 lg:px-8">
      <Toaster position="top-right" />
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Help us make a difference</h1>
      <WhiteCard>
        <Flex className="flex-col space-y-4">
          {jobPositions.map((position) => (
            <Card
              key={position.id}
              onClick={() => handleCardClick(position)}
              className="cursor-pointer hover:shadow-lg transition-shadow duration-300 bg-white rounded-lg p-6"
            >
              <Heading level={4} className="text-xl font-semibold text-gray-800 mb-2">{position.title}</Heading>
              <Text className="text-gray-600">{position.description}</Text>
            </Card>
          ))}
        </Flex>
        {selectedPosition && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center overflow-y-auto pt-20 px-4">
            <div className="bg-white rounded-lg p-8 max-w-2xl w-full my-8 max-h-[calc(100vh-160px)] overflow-y-auto">
              <Heading level={2} className="text-2xl font-bold mb-4">Apply for {selectedPosition.title}</Heading>
              <div className="space-y-4 mb-6">
                {['Responsibilities', 'Skills Required', 'Desirables', 'Employee Benefits', 'Person Specification'].map((section) => (
                  <div key={section}>
                    <Heading level={4} className="text-lg font-semibold mb-2">{section}:</Heading>
                    {renderList(selectedPosition[section.toLowerCase().replace(' ', '') as keyof JobPosition])}
                  </div>
                ))}
              </div>
              <form onSubmit={handleSubmit} className="space-y-4">
                <Input
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <Input
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                />
                <div>
                  <label htmlFor="cv" className="block text-sm font-medium text-gray-700 mb-1">Upload your Curriculum Vitae:</label>
                  <Input
                    id="cv"
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                    required
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
                <textarea
                  name="description"
                  placeholder="Why are you a good fit for this position?"
                  value={formData.description}
                  onChange={(e) => setFormData((prevData) => ({ ...prevData, description: e.target.value }))}
                  required
                  className="w-full p-2 border border-gray-300 rounded h-32"
                ></textarea>
                <div className="flex justify-end space-x-4">
                  <Button type="submit" isLoading={isSubmitting} loadingText="Submitting..." className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Submit Application
                  </Button>
                  <Button onClick={() => setSelectedPosition(null)} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
                    Close
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}
      </WhiteCard>
    </div>
  );
}