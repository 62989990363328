import React, { useEffect, useState, useCallback } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, CheckboxField, Badge, Text, Loader } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import TableHeader from "./TableHeader";
import { Slide, ThumbnailUrls, Inference } from "./types";

const generatePresignedURL = async (key: string | null): Promise<string> => {
    if (!key) return "";
    try {
        console.log(key);
        const presignedUrlResponse = await API.get('histopathologyAPI', '/presignedUrl_get', {
            queryStringParameters: { bucket: 'svs-to-compute', key }
        });

        return presignedUrlResponse.url;
    } catch (error) {
        console.error('Error generating presigned URL:', error);
        return "";
    }
};

const SlidesTable: React.FC<{ userId: string; subjectId: string }> = ({ userId, subjectId }) => {
    const [slides, setSlides] = useState<Slide[]>([]);
    const [thumbnailUrls, setThumbnailUrls] = useState<ThumbnailUrls>({});
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [inferences, setInferences] = useState<Inference[]>([]);

    const fetchInferences = useCallback(async () => {
        try {
            const response = await API.get('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/inferences`, {});
            setInferences(response.data);
        } catch (error) {
            console.error('Error fetching inferences:', error);
        }
    }, [userId, subjectId]);

    const fetchSlides = useCallback(async () => {
        try {
            const response = await API.get('histopathologyAPI', `/users/${userId}/subjects/${subjectId}/slides`, {});
            const fetchedSlides: Slide[] = response.data;
            setSlides(fetchedSlides);

            const urls = await Promise.all(
                fetchedSlides.map(async (slide) => ({
                    [slide.id]: await generatePresignedURL(`subjects/${subjectId}/slides/${slide.id}/thumbnail.jpg`)
                }))
            );
            setThumbnailUrls(Object.assign({}, ...urls));
        } catch (error) {
            console.error('Error fetching slides:', error);
        }
    }, [userId, subjectId]);

    useEffect(() => {
        fetchSlides();
        fetchInferences();
        const interval = setInterval(fetchInferences, 5000);
        return () => clearInterval(interval);
    }, [fetchSlides, fetchInferences]);

    const handleSelectAllChange = () => {
        setSelectedRows(selectAll ? [] : slides.map(slide => slide.id));
        setSelectAll(!selectAll);
    };

    const handleRowCheckboxChange = (slideId: string) => {
        setSelectedRows(prev =>
            prev.includes(slideId) ? prev.filter(id => id !== slideId) : [...prev, slideId]
        );
    };

    const renderSlideRow = (slide: Slide) => (
        <TableRow key={slide.id}>
            <TableCell>
                <CheckboxField
                    label=""
                    name=""
                    value="yes"
                    checked={selectedRows.includes(slide.id)}
                    onChange={() => handleRowCheckboxChange(slide.id)}
                />
            </TableCell>
            <TableCell>{slide.id}</TableCell>
            <TableCell>
                {slide.isconverted ? (
                    <img width="100" src={thumbnailUrls[slide.id] || ''} alt={`Thumbnail for ${slide.id}`} />
                ) : (
                    <Loader />
                )}
            </TableCell>
            <TableCell>{slide.filename}</TableCell>
            <TableCell>
                {inferences
                    .filter(inference => inference.slide_id === slide.id)
                    .map((inference: Inference) => (
                        <Badge
                            key={inference.inference_type}
                            style={{ marginBottom: '4px' }}
                            variation={inference.job_status !== "COMPLETED" ? 'info' : 'success'}
                        >
                            {inference.inference_type}
                        </Badge>
                    ))}
            </TableCell>
            <TableCell>
                {slide.isconverted ? (
                    <Link to={`/subjects/${subjectId}/slides/${slide.id}/view`} style={{ color: 'blue', textDecoration: 'underline' }}>
                        View Slide
                    </Link>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <Text>Converting..</Text>
                        <Loader />
                    </div>
                )}
            </TableCell>
        </TableRow>
    );

    const renderMobileCard = (slide: Slide) => (
        <div key={slide.id} className="bg-white rounded-lg shadow-md p-4 mb-4">
          <div className="flex items-center justify-between mb-2">
            <CheckboxField 
              label="" 
              name="" 
              value="yes" 
              checked={selectedRows.includes(slide.id)}
              onChange={() => handleRowCheckboxChange(slide.id)} 
            />
            <span className="text-sm font-medium">ID: {slide.id}</span>
          </div>
          <div className="space-y-3">
            {slide.isconverted ? (
              <Link to={`/subjects/${subjectId}/slides/${slide.id}/view`}>
                <div className="flex justify-center">
                  <img 
                    className="object-contain cursor-pointer" 
                    src={thumbnailUrls[slide.id] || ''} 
                    alt={`Thumbnail for ${slide.id}`}
                  />
                </div>
              </Link>
            ) : (
              <div className="flex justify-center">
                <Loader />
              </div>
            )}
            <div>
              <span className="text-sm font-medium">{slide.filename}</span>
            </div>
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium">Inferences:</span>
              <div className="flex flex-wrap gap-1">
                {inferences
                  .filter(inference => inference.slide_id === slide.id)
                  .map((inference: Inference) => (
                    <Badge 
                      key={inference.inference_type}
                      variation={inference.job_status !== "COMPLETED" ? 'info' : 'success'}
                    >
                      {inference.inference_type}
                    </Badge>
                  ))}
              </div>
            </div>
            {!slide.isconverted && (
              <div className="flex flex-col items-center justify-center">
                <Text>Converting..</Text>
                <Loader />
              </div>
            )}
          </div>
        </div>
      );

    if (slides.length === 0) return null;

    return (
        <div className="w-full">
            <TableHeader
                selectedRows={selectedRows}
                userId={userId}
                subjectId={subjectId}
                fetchSlides={fetchSlides}
                setSelectAll={setSelectAll}
                setSelectedRows={setSelectedRows}
            />

            {/* Mobile View */}
            <div className="sm:hidden">
                <button
                    onClick={handleSelectAllChange}
                    className="text-blue-600 underline mb-4"
                >
                    Select All
                </button>
                {slides.map(renderMobileCard)}
            </div>

            {/* Desktop View */}
            <div className="hidden sm:block">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <button
                                    onClick={handleSelectAllChange}
                                    className="bg-transparent border-none p-0 text-blue-600 underline cursor-pointer"
                                >
                                    Select All
                                </button>
                            </TableCell>
                            <TableCell>Slide ID</TableCell>
                            <TableCell>Thumbnail</TableCell>
                            <TableCell>Slide Filename</TableCell>
                            <TableCell>Inferences</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {slides.map(renderSlideRow)}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default SlidesTable;