import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const teamMembers = [
  {
    name: 'Dr. Charles-Antoine Collins-Fekete',
    role: 'Team Lead',
    image: 'CACF.png',
    description: "Dr. Collins-Fekete is a UKRI Future Leaders Fellow at UCL, leading research in AI for cancer diagnosis with a focus on digital pathology. He has established a team of 7 post-doctoral researchers, published over 30 peer-reviewed papers, and secured substantial funding exceeding £3 Mio. As founder of the Octopath spin-out and co-founder of the UCL Cancer Collaboratorium, he drives the translation of cutting-edge science into impactful solutions for cancer care.",
  },
  {
    name: 'Zhuoyan Shen',
    role: 'AI Research Scientist',
    image: 'ZS.png',
    description: 'Dr. Zhuoyan Shen is a research fellow specializing in object detection AI, with expertise in hematoxylin and eosin cell classification for digital pathology. She has created the largest database of mitoses and lymphocytes to date, developing AI algorithms to transform digital pathology practices.',
  },
  {
    name: 'Mikael Simard',
    role: 'AI Research Scientist',
    image: 'MS.png',
    description: "Dr. Mikael Simard has designed an innovative sarcoma classification AI algorithm using a gated transformer attention network, capable of classifying up to 29 sarcoma sub-types. His expertise includes data management, database creation, algorithm development, external validation, and continuous integration of AI solutions.",
  },
  {
    name: 'Esther Baer',
    role: 'Business Manager',
    image: 'EB.jpg',
    description: "Dr. Esther Baer is a Senior Research Fellow and Certified Clinical Scientist with extensive experience in AI and Medical Imaging. Her expertise lies in translating research into clinical practice, having implemented AI models in NHS systems. As a UKRI RadNet Clinical Translation fellow, she has successfully integrated novel diagnostic imaging tools into radiotherapy practice, demonstrating her ability to work across disciplines and engage with clinicians.",
  },
];
const TeamMember = ({ member, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0 }}
      animate={inView ? { opacity: 1 } : { opacity: 0 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      className="flex flex-col md:flex-row items-center mb-16 bg-white"
    >
      <motion.div
        className="w-64 h-64 mb-4 md:mb-0 md:mr-8 overflow-hidden rounded-full flex-shrink-0 bg-white"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={inView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.5, delay: index * 0.2 }}
      >
        <img
          src={member.image}
          alt={member.name}
          className="w-full h-full object-cover rounded-full grayscale"
          loading="lazy"
        />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
        transition={{ duration: 0.5, delay: index * 0.2 + 0.2 }}
        className="flex-grow"
      >
        <h2 className="text-2xl font-bold mb-2">{member.name}</h2>
        <h3 className="text-xl text-gray-600 mb-4">{member.role}</h3>
        <p className="text-gray-700">{member.description}</p>
      </motion.div>
    </motion.div>
  );
};
const Teams = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-4xl font-bold text-center mb-12">Our Team</h1>
        {teamMembers.map((member, index) => (
          <TeamMember key={member.name} member={member} index={index} />
        ))}
      </div>
    </div>
  );
};

export default Teams;