import {useEffect, useState} from "react";
import {Button, Table, TableBody, TableCell, TableHead, TableRow} from "@aws-amplify/ui-react";
import {API} from "aws-amplify";
import {useNavigate} from "react-router-dom";
import {getUserID} from "../api/api";
import {AddSubjectForm} from "../components/AddSubjectForm";

// Define the structure of each subject object
interface Subject {
    id: string;
    name: string;
    age: string;
    diagnosis: string;
}


const SubjectsTable = () => {
    let navigate = useNavigate();

    const [isAddSubjectOpen, setIsAddSubjectOpen] = useState(false);

    const [subjects, setSubjects] = useState<Subject[]>([]); // Use the Subject interface for typing the state
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
    const totalPages = 1;

    const noSubjects = subjects.length === 0;

    const handleNextPage = () => {
        setCurrentPageIndex(currentPageIndex + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPageIndex(currentPageIndex - 1);
    };

    const handleOnChange = (newPageIndex?: number | undefined, prevPageIndex?: number | undefined) => {
        if (newPageIndex === undefined) return;
        setCurrentPageIndex(newPageIndex);
    };

    useEffect(() => {
        fetchSubjects();
    }, []);

    const fetchSubjects = async () => {
        setIsLoading(true);
        try {
            const userID = await getUserID();
            const response = await API.get("histopathologyAPI", `/users/${userID}/subjects`, {});
            setSubjects(response.data);
        } catch (error) {
            console.error("Error fetching subjects:", error);
        } finally {
            setIsLoading(false);
        }
    };

    function handleViewSlidesClicked(SubjectID: string) {
        navigate(`/subjects/${SubjectID}/slides`);
    }

    function handleEditClicked(SubjectID: string) {
        navigate(`/subjects/${SubjectID}/view`);
    }

    return (
        <>
            {noSubjects && <p>No subjects found, please click 'Add New Subject' below.</p>}
            {!noSubjects &&
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Subject Identifier</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {subjects.map(subject => (
                                <TableRow key={subject.id}>
                                    <TableCell>{subject.name}</TableCell>
                                    <TableCell>
                                        <Button variation="link"
                                                onClick={() => handleViewSlidesClicked(subject.id)}>
                                            View Slides
                                        </Button>
                                        <Button variation="link"
                                                onClick={() => handleEditClicked(subject.id)}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            }
            {/*<Pagination*/}
            {/*currentPage={currentPageIndex}*/}
            {/*totalPages={totalPages}*/}
            {/*onNext={handleNextPage}*/}
            {/*onPrevious={handlePreviousPage}*/}
            {/*onChange={handleOnChange}*/}
            {/*/>*/}

            <Button style={{marginTop: "1rem"}} onClick={() => setIsAddSubjectOpen(!isAddSubjectOpen)}>Add New
                Subject</Button>

            <AddSubjectForm
                isOpen={isAddSubjectOpen}
                onClose={() => setIsAddSubjectOpen(false)}
                onSubjectAdded={fetchSubjects}
            />
        </>
    );
};

export default SubjectsTable;
