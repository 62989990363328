import { MutableRefObject } from "react";
import { API } from "aws-amplify";
import { Viewer } from "openseadragon";
import Papa from "papaparse";

interface AttentionDataCSVItem {
    attention_KDE_mag40: number;
    attention_KDE_mag20: number;
    attention_KDE_mag10: number;
    attention_mag40: number;
    attention_mag20: number;
    attention_mag10: number;
    coords_x: number;
    coords_y: number;
}

interface ObjectCSVItem {
    coords_x: number;
    coords_y: number;
    conf: number;
    class: string;
}

interface SlideParameters {
    userId: string;
    subjectId: string;
    slideId: string;
    level: 'public' | 'protected' | 'private';
    slidePath: string;
}
const CLASS_COLORS = {
    '0': '#81CAD6', 
    '1': '#BFFF00', 
    '2': '#DC3E26'  
};

const fetchAndParseCSV = async function (slideParameters: SlideParameters, results_filename: string) {
    const presignedUrlResponse = await API.get('histopathologyAPI', '/presignedUrl_get', {
        queryStringParameters: {
            bucket: 'svs-to-compute',
            key: `${slideParameters.slidePath}/${results_filename}`
        }
    });
    const url = presignedUrlResponse.url;
    const response = await fetch(url as string);
    const csvText = await response.text();
    // Parse the CSV file
    return new Promise((resolve, reject) => {
        Papa.parse(csvText, {
            header: true,
            dynamicTyping: true,
            complete: results => {
                resolve(results.data);
            },
            error: (error: any) => {
                reject(error);
            }
        });
    });
}

export const paintObject = async function (
    viewer: Viewer,
    heatmapRef: MutableRefObject<HeatmapOverlay | null>,
    filteredObjectData: ObjectCSVItem[]
) {
    if (!heatmapRef.current) {
        heatmapRef.current = new HeatmapOverlay(viewer, {
            backgroundColor: 'rgba(0,0,0,0)',
            maxOpacity: 0.5,
            minOpacity: 0.25,
            typeofHeatmap: 'mitoses'
        });
    }

    heatmapRef.current.heatmap._renderer.typeofHeatmap = 'mitoses';
    const points = filteredObjectData.map((item: ObjectCSVItem) => ({
        x: Number(item.coords_x),
        y: Number(item.coords_y),
        color: CLASS_COLORS[item.class as keyof typeof CLASS_COLORS] || '#000000',
        value: 1.0,
        radius: 1
    }));
    console.log(points);
    const data = { min: 0, max: 1, data: points };
    if (heatmapRef.current) {
        heatmapRef.current.setData(data);
    }
};

export const paintBbox = function (
    heatmapRef: MutableRefObject<HeatmapOverlay | null>,
    bbox: number[] | null
) {
    if (heatmapRef.current && bbox) {
        heatmapRef.current.setBbox(bbox);
    }
};

export const paintAttentionMap = async function (
    viewer: Viewer,
    heatmapRef: MutableRefObject<HeatmapOverlay | null>,
    slideParameters: SlideParameters,
    mpp: MutableRefObject<null>,
) {
    if (!heatmapRef.current) {
        heatmapRef.current = new HeatmapOverlay(viewer, {
            backgroundColor: 'rgba(0,0,0,0)',
            maxOpacity: 0.5,
            minOpacity: 0.25
        });
    }
    heatmapRef.current.heatmap._renderer.typeofHeatmap = ''; // If loaded from elsewhere
    const dataCSV = await fetchAndParseCSV(slideParameters, 'att_mag.csv') as AttentionDataCSVItem[];
    const filteredDataCSV = dataCSV.filter(item =>
        item.attention_KDE_mag40 !== null && !isNaN(Number(item.attention_KDE_mag40))
    );

    const MPP_calib = 123760;
    const points = filteredDataCSV.map((item: AttentionDataCSVItem) => ({
        x: Number(item.coords_x),
        y: Number(item.coords_y),
        value: Number(item.attention_KDE_mag40), // Already clipped and normalized
        radius: 0.827 * Number(MPP_calib) / Number(mpp.current) //2 * 1.114
    }));
    var data = {
        min: 0,
        max: 1,
        data: points
    };

    if (heatmapRef.current) {
        heatmapRef.current.setData(data);
    }

};
